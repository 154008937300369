import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

function Home() {
  useEffect(() => {
    // Load external JavaScript scripts here
    const script1 = document.createElement("script");
    script1.src = "assets/js/main.js";
    script1.async = true;
    document.body.appendChild(script1);
    return () => {
      document.body.removeChild(script1);
    };
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use smooth scrolling for a smoother animation (optional)
      });
    };

    // Call the scrollToTop function when the component mounts
    scrollToTop();
  }, []);

  const [about_us, setabout_us] = useState([]);
  useEffect(() => {
    getabout_us();
  }, []);

  const getabout_us = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/about_us/`)
        .then(function (response) {
          setabout_us(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  /*       const [banner, setbanner] = useState([]);
      useEffect(() => {
        getbanner();
      }, []);
    
      const getbanner = async () => {
        try {
          await axios
            .get(`${process.env.REACT_APP_API_BASE}/banner/`)
            .then(function (response) {
              setbanner(response.data);
            });
        } catch (err) {
          console.error(err);
        }
      }; 
      
          let banner_html_data = banner.map((banner, key) => (
        <div key={key} className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
        <div className="ltn__slide-item-inner">
            <div className="container">
            <div className="row">
                <div className="col-lg-12 align-self-center">
                <div className="slide-item-info">
                    <div className="slide-item-info-inner ltn__slide-animation">
                    <h6 className="slide-sub-title white-color--- animated">Bagrecha Enterprises Limited</h6>
                    <h1 className="slide-title animated ">Tittle 1 <br></br><span>Luxury</span></h1>
                    <div className="btn-wrapper animated">
                        <a href="Contact-Us" className="theme-btn-1 btn btn-effect-1">Make An Enquiry</a>
                    </div>
                    </div>
                </div>
                <div className="slide-item-img">
                    <img src="assets/img/slider/sample.jpg" alt="#" />
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    ));
    */

  const [our_partners, setour_partners] = useState([]);
  useEffect(() => {
    getour_partners();
  }, []);

  const getour_partners = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/our_partners/`)
        .then(function (response) {
          setour_partners(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let our_partners_html_data = our_partners.map((our_partners, key) => (
    <div key={key} className="ltn__brand-logo-item">
      <Image
        src={`.${our_partners?.image_path.substring(16, 1000) || ""}`}
        alt={our_partners.product_name}
      />
    </div>
  ));

  const [products, setproducts] = useState([]);

  useEffect(() => {
    getproducts();
  }, []);

  const getproducts = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/top_products/`)
        .then(function (response) {
          setproducts(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let products_html_data = products.map((products, key) => (
    <div className="col-xl-4 col-sm-6 col-12">
      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
        <div className="product-img">
          <Link to={`/ProductDetails?product_id=${products.product_id}`}>
            <Image
              src={`.${products?.image_1_path.substring(16, 1000) || ""}`}
              alt={products.product_name}
            />
          </Link>
        </div>
        <div className="product-info">
          <h2 className="product-title">
            <Link to={`/ProductDetails?product_id=${products.product_id}`}>
              {products.product_name}
            </Link>
          </h2>
        </div>
      </div>
    </div>
  ));

  const settings = {
    infinite: true,
    arrows: false,
    speed: 1000, // Adjust the speed as needed
    autoplay: true,
    slidesToShow: 4, // Adjust the number of logos shown at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div>
        <div className="ltn__utilize-overlay" />
        {/* SLIDER AREA START (slider-11) */}
        <div className="ltn__slider-area ltn__slider-11  ltn__slider-11-slide-item-count-show--- ltn__slider-11-pagination-count-show--- section-bg-1">
          <div className="ltn__slider-11-inner">
            <div className="ltn__slider-11-active">
              {/* slide-item */}
              <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
                <div className="ltn__slide-item-inner">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 align-self-center">
                        <div className="slide-item-info">
                          <div className="slide-item-info-inner ltn__slide-animation">
                            <h6 className="slide-sub-title white-color--- animated">
                              Bagrecha Enterprises Limited
                            </h6>
                            <h1 className="slide-title animated ">
                              Crystal Clear<br></br>
                              <br></br>
                              <span>Solutions </span>Always
                            </h1>
                            <div className="btn-wrapper animated">
                              <a
                                href="Contact-Us"
                                className="theme-btn-1 btn btn-effect-1"
                              >
                                Make An Enquiry
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="slide-item-img">
                          <img src="assets/img/slider/slider-1.jpg" alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* slide-item */}
              <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
                <div className="ltn__slide-item-inner">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 align-self-center">
                        <div className="slide-item-info">
                          <div className="slide-item-info-inner ltn__slide-animation">
                            <h6 className="slide-sub-title white-color--- animated">
                              Bagrecha Enterprises Limited
                            </h6>
                            <h1 className="slide-title animated ">
                              <span>Brilliance </span>
                              <br></br> in Every Pane.
                            </h1>
                            <div className="btn-wrapper animated">
                              <a
                                href="Contact-Us"
                                className="theme-btn-1 btn btn-effect-1"
                              >
                                Make An Enquiry
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="slide-item-img">
                          <img src="assets/img/slider/slider-2.jpg" alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* slide-item */}
              <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
                <div className="ltn__slide-item-inner">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 align-self-center">
                        <div className="slide-item-info">
                          <div className="slide-item-info-inner ltn__slide-animation">
                            <h6 className="slide-sub-title white-color--- animated">
                              Bagrecha Enterprises Limited
                            </h6>
                            <h1 className="slide-title animated ">
                              <span>Precision </span>
                              <br></br> in Every Sheet
                            </h1>
                            <div className="btn-wrapper animated">
                              <a
                                href="Contact-Us"
                                className="theme-btn-1 btn btn-effect-1"
                              >
                                Make An Enquiry
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="slide-item-img">
                          <img src="assets/img/slider/slider-3.jpg" alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* slide-item */}
              <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3 ltn__slide-item-11">
                <div className="ltn__slide-item-inner">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 align-self-center">
                        <div className="slide-item-info">
                          <div className="slide-item-info-inner ltn__slide-animation">
                            <h6 className="slide-sub-title white-color--- animated">
                              Bagrecha Enterprises Limited
                            </h6>
                            <h1 className="slide-title animated ">
                              <span>Clear Visions </span>
                              <br></br>Seamless Supply
                            </h1>
                            <div className="btn-wrapper animated">
                              <a
                                href="Contact-Us"
                                className="theme-btn-1 btn btn-effect-1"
                              >
                                Make An Enquiry
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="slide-item-img">
                          <img src="assets/img/slider/slider-4.jpg" alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* slider-4-pagination */}
            <div className="ltn__slider-11-pagination-count">
              <span className="count" />
              <span className="total" />
            </div>
            {/* slider-sticky-icon */}
            <div className="slider-sticky-icon-2">
              <ul>
                <li>
                  <a href="Home" title="Facebook">
                    <i className="fab fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="Home" title="Instagram">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
            {/* slider-4-img-slide-arrow */}
            <div className="ltn__slider-11-img-slide-arrow">
              <div className="ltn__slider-11-img-slide-arrow-inner">
                <div className="ltn__slider-11-img-slide-arrow-active">
                  <div className="image-slide-item">
                    <img src="assets/img/slider/slider-1.jpg" alt="Flower" />
                  </div>
                  <div className="image-slide-item">
                    <img src="assets/img/slider/slider-2.jpg" alt="Flower" />
                  </div>
                  <div className="image-slide-item">
                    <img src="assets/img/slider/slider-3.jpg" alt="Flower" />
                  </div>
                  <div className="image-slide-item">
                    <img src="assets/img/slider/slider-4.jpg" alt="Flower" />
                  </div>
                </div>
                {/* slider-4-slide-item-count */}
                <div className="ltn__slider-11-slide-item-count">
                  <span className="count" />
                  <span className="total" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SLIDER AREA END */}

        {/* ABOUT US AREA START */}
        <div className="ltn__about-us-area pt-115 pb-100 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="about-us-img-wrap about-img-left">
                  <Image
                    src={`.${
                      about_us[0]?.image_path.substring(16, 1000) || ""
                    }`}
                    alt="image"
                  />
                  <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3 d-none">
                    <div className="ltn__video-img ltn__animation-pulse1">
                      <Image
                        src={`.${
                          about_us[0]?.image_path.substring(16, 1000) || ""
                        }`}
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="about-us-info-wrap">
                  <div className="section-title-area ltn__section-title-2--- mb-30">
                    <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                      About Us
                    </h6>
                    <h1 className="section-title">Who Are We ?</h1>
                    <p>{about_us[0]?.content_1}</p>
                    <p>{about_us[0]?.content_2}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ABOUT US AREA END */}

        {/* FEATURE AREA START ( Feature - 6) */}
        <div className="ltn__feature-area section-bg-1--- pt-115 pb-90 mb-120---">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
{/*                   <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                    What We have Done
                  </h6> */}
                  <h1 className="section-title">HOW IT WORKS</h1>
                </div>
              </div>
            </div>
            <div className="row ltn__custom-gutter---  justify-content-center">
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                  <div className="ltn__feature-icon">
                    <img
                      src="assets/img/icons/icon-img/21-a.png"
                      alt="#"
                      style={{ maxWidth: "50%", height: "50px" }}
                    />
                  </div>
                  <div className="ltn__feature-info">
                    <h5>
                      <a href="Home">1. Enquire Online</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                  <div className="ltn__feature-icon">
                    <img
                      src="assets/img/icons/icon-img/21.png"
                      alt="#"
                      style={{ maxWidth: "50%", height: "50px" }}
                    />
                  </div>
                  <div className="ltn__feature-info">
                    <h5>
                      <a href="Home">2. Confirmation</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                  <div className="ltn__feature-icon">
                    <img
                      src="assets/img/icons/icon-img/21-b.png"
                      alt="#"
                      style={{ maxWidth: "50%", height: "50px" }}
                    />
                  </div>
                  <div className="ltn__feature-info">
                    <h5>
                      <a href="Home">3. WhatsApp Connect</a>
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                  <div className="ltn__feature-icon">
                    <img
                      src="assets/img/icons/icon-img/21-c.png"
                      alt="#"
                      style={{ maxWidth: "50%", height: "50px" }}
                    />
                  </div>
                  <div className="ltn__feature-info">
                    <h5>
                      <a href="Home">4. Delivery</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FEATURE AREA END */}

        {/* BLOG AREA START (blog-3) */}
        <div className="ltn__blog-area pt-115--- pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
                  <h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">
                    Our &amp; Products
                  </h6>
                  <h1 className="section-title">Our Top Selling products</h1>
                </div>
              </div>
            </div>
            <div className="ltn__product-area ltn__product-gutter mb-120">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show"
                        id="liton_product_grid"
                      >
                        <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                          <div className="row">{products_html_data}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BLOG AREA END */}

        {/* BRAND LOGO AREA START */}
        <div className="ltn__brand-logo-area ltn__brand-logo-1 pt-80--- pb-110 plr--9">
          <div className="container-fluid">
            <Slider {...settings}>{our_partners_html_data}</Slider>
          </div>
        </div>
        {/* BRAND LOGO AREA END */}

        {/* CALL TO ACTION START (call-to-action-6) */}
        <div
          className="ltn__call-to-action-area call-to-action-6 before-bg-bottom"
          data-bg="img/1.jpg--"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg text-center---">
                  <div className="coll-to-info text-color-white">
                    <h1>Looking for Best Glass Product ?</h1>
                  </div>
                  <div className="btn-wrapper">
                    <a className="btn btn-effect-3 btn-white" href="Contact-Us">
                      Contact Us
                      <i className="icon-next" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* CALL TO ACTION END */}
      </div>
    </div>
  );
}

export default Home;
