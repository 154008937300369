import React from 'react'
import axios from "axios";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

function Products() {

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use smooth scrolling for a smoother animation (optional)
      });
    };

    // Call the scrollToTop function when the component mounts
    scrollToTop();
  }, []);

  const [products, setproducts] = useState([]);
  useEffect(() => {
    getproducts();
  }, []);

  const getproducts = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/products/`)
        .then(function (response) {
          setproducts(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  let products_html_data = products.map((products, key) => (
    <div key={key} className="col-lg-6">
    <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
        <div className="product-img">
        <Link to={`/ProductDetails?product_id=${products.product_id}`}><Image src={`.${products?.image_1_path.substring(16, 1000) || ''}`} alt={products.product_name} /></Link>
        </div>
        <div className="product-info">
        <h2 className="product-title"><Link to={`/ProductDetails?product_id=${products.product_id}`}>{products.product_name}</Link></h2>
        <div className="product-img-location">
            <Link to={`/ProductDetails?product_id=${products.product_id}`}>{products.description}</Link>
        </div>
        </div>
    </div>
    </div>
  ));

  let products_name_html_data = products.map((products, key) => (
    <Link to={`/ProductDetails?product_id=${products.product_id}`} className="product-container">{products.product_name}</Link>
  ));

  return (
    <div>
      <div>
        <div className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image" data-bs-bg="assets/img/bg/14.jpg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner">
                  <h1 className="page-title">Products</h1>
                  <div className="ltn__breadcrumb-list">
                    <ul>
                      <li><Link to="Home"><span className="ltn__secondary-color"><i className="fas fa-home" /></span> Home</Link></li>
                      <li>Products</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ltn__product-area ltn__product-gutter mb-120">
            <div className="container">
                <div className="row">
                <div className="col-lg-3">
                  <div className="ltn__tab-menu-list mb-50">
                    <div className="nav">
                      {products_name_html_data}
                    </div>
                  </div>
                </div>

                <div className="col-lg-9">
                    <div className="tab-content">
                    <div className="tab-pane fade active show" id="liton_product_list">
                        <div className="ltn__product-tab-content-inner ltn__product-list-view">
                        <div className="row">
                            {products_html_data}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Products;