import React, { useEffect, useState } from "react";
import axios from "axios";
import { Image } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

function ProductDetails() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/main.js";
    document.head.appendChild(script);

    // Clean up the script when the component unmounts or the script source changes
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("product_id");

  useEffect(() => {
    // Extract product_id from the location query parameter
    const searchParams = new URLSearchParams(location.search);
    const productIdFromUrl = searchParams.get("product_id");

    // Update the state only if productId is different
    if (productId !== productIdFromUrl) {
    }
  }, [location.search, productId]);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    scrollToTop();
  }, []);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE}/products/`
      );
      setProducts(response.data);

      // Dynamically create and append the script tag to the document head
      const script = document.createElement("script");
      script.src = "assets/js/main.js";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      // Clean up the script when the component unmounts
      return () => {
        document.head.removeChild(script);
      };
    } catch (err) {
      console.error(err);
    }
  };

  const targetProductId = +productId; // replace with the desired product_id
  const targetProduct = products.find(
    (product) => product.product_id === targetProductId
  );

  return (
    <div>
      {/* BREADCRUMB AREA START */}
      <div
        className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image mb-0"
        data-bs-bg="assets/img/bg/14.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner">
                <h1 className="page-title">Product details</h1>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <a href="Home">
                        <span className="ltn__secondary-color">
                          <i className="fas fa-home" />
                        </span>{" "}
                        Home
                      </a>
                    </li>
                    <li>Product details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* BREADCRUMB AREA END */}

      <div className="ltn__shop-details-area pb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="ltn__shop-details-inner ltn__page-details-inner mb-80">
                {/* Conditional rendering based on whether products have data */}
                {targetProduct ? (
                  <>
                    <h1>{targetProduct.product_name}</h1>
                    {/* Features */}
                    {targetProduct.feature && (
                      <>
                        <h4 className="title-2">Features</h4>
                        <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                          <ol>
                            <p
                              style={{
                                left: "20px",
                                position: "relative",
                                margin: "10px",
                                top: "10px",
                                paddingBottom: "20px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: targetProduct.feature,
                              }}
                            ></p>
                          </ol>
                        </div>
                      </>
                    )}

                    {/* Specification */}
                    {targetProduct.specification && (
                      <>
                        <h4 className="title-2">Specification</h4>
                        <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                          <ol>
                            <p
                              style={{
                                left: "20px",
                                position: "relative",
                                margin: "10px",
                                top: "10px",
                                paddingBottom: "20px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: targetProduct.specification,
                              }}
                            ></p>
                          </ol>
                        </div>
                      </>
                    )}

                    {/* Applications */}
                    {targetProduct.application && (
                      <>
                        <h4 className="title-2">Applications</h4>
                        <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                          <ol>
                            <p
                              style={{
                                left: "20px",
                                position: "relative",
                                margin: "10px",
                                top: "10px",
                                paddingBottom: "20px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: targetProduct.application,
                              }}
                            ></p>
                          </ol>
                        </div>
                      </>
                    )}

                    {/* Color */}
                    {targetProduct.color && (
                      <>
                        <h4 className="title-2">Color</h4>
                        <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                          <ol>
                            <p
                              style={{
                                left: "20px",
                                position: "relative",
                                margin: "10px",
                                top: "10px",
                                paddingBottom: "20px",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: targetProduct.color,
                              }}
                            ></p>
                          </ol>
                        </div>
                      </>
                    )}

                    <h4 className="title-2">Images</h4>
                    <div className="ltn__property-details-gallery mb-30">
                      <div className="row">
                        <div className="col-md-4">
                          <a
                            href={`.${
                              targetProduct?.image_1_path.substring(16, 1000) ||
                              ""
                            }`}
                            data-rel="lightcase:myCollection"
                          >
                            <Image
                              className="mb-30"
                              src={`.${
                                targetProduct?.image_1_path.substring(
                                  16,
                                  1000
                                ) || ""
                              }`}
                              alt={targetProduct.product_name}
                            />
                          </a>
                        </div>
                        <div className="col-md-4">
                          <a
                            href={`.${
                              targetProduct?.image_2_path.substring(16, 1000) ||
                              ""
                            }`}
                            data-rel="lightcase:myCollection"
                          >
                            <Image
                              className="mb-30"
                              src={`.${
                                targetProduct?.image_2_path.substring(
                                  16,
                                  1000
                                ) || ""
                              }`}
                              alt={targetProduct.product_name}
                            />
                          </a>
                        </div>
                        <div className="col-md-4">
                          <a
                            href={`.${
                              targetProduct?.image_3_path.substring(16, 1000) ||
                              ""
                            }`}
                            data-rel="lightcase:myCollection"
                          >
                            <Image
                              className="mb-30"
                              src={`.${
                                targetProduct?.image_3_path.substring(
                                  16,
                                  1000
                                ) || ""
                              }`}
                              alt={targetProduct.product_name}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrapper animated">
                <Link to={`/ProductGallery?gallery_id=${targetProduct.product_id}`} className="theme-btn-1 btn btn-effect-1">
                    More Images
                  </Link>
                  <Link to="/Products" className="theme-btn-1 btn btn-effect-1">
                    More Products
                  </Link>
                </div>
                  </>
                ) : (
                  // Render loading state or a message while waiting for data
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
