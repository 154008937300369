import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import Header from "./components/Header";
import Footer from './components/Footer';
import Home from "./components/Home";
import Contact from './components/Contact';
import ProductDetails from './components/ProductDetails';
import AboutUs from './components/AboutUs';
import Gallery from './components/Gallery';
import Products from './components/Products';
import IndividualGallery from './components/IndividualGallery';

function App() {
  return (
    <div className="App">
        <Router>
        <Header></Header>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/Home' element={<Home/>}/>
          <Route exact path='/About-Us' element={<AboutUs/>}/>
          <Route exact path='/Products' element={<Products/>}/>
          <Route exact path='/Gallery' element={<Gallery/>}/>
          <Route exact path='/ProductDetails' element={<ProductDetails/>}/>
          <Route exact path='/ProductGallery' element={<IndividualGallery/>}/>
          <Route exact path='/Contact-Us' element={<Contact/>}/>
        </Routes>
        <Footer></Footer>
        </Router>
    </div>
  );
}

export default App;
