import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

function AboutUs() {
  useEffect(() => {
    // Load external JavaScript scripts here
    const script1 = document.createElement("script");
    script1.src = "assets/js/main.js";
    script1.async = true;
    document.body.appendChild(script1);
    return () => {
      document.body.removeChild(script1);
    };
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use smooth scrolling for a smoother animation (optional)
      });
    };

    // Call the scrollToTop function when the component mounts
    scrollToTop();
  }, []);

  const [about_us, setabout_us] = useState([]);
  useEffect(() => {
    getabout_us();
  }, []);

  const getabout_us = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/about_us/`)
        .then(function (response) {
          setabout_us(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div>
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "
          data-bs-bg="assets/img/bg/14.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner">
                  <h1 className="page-title">About Us</h1>
                  <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <Link to="Home">
                          <span className="ltn__secondary-color">
                            <i className="fas fa-home" />
                          </span>{" "}
                          Home
                        </Link>
                      </li>
                      <li>About Us</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}
        {/* ABOUT US AREA START */}
        <div className="ltn__about-us-area pt-120--- pb-90 mt--30">
          <div className="container">
            <div className="row">
              {about_us[0] ? (
                <>
                  <div className="col-lg-6 align-self-center">
                    <div className="about-us-img-wrap about-img-left">
                      <Image
                        src={`.${
                          about_us[0]?.image_path.substring(16, 1000) || ""
                        }`}
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 align-self-center">
                    <div className="about-us-info-wrap">
                      <div className="section-title-area ltn__section-title-2--- mb-20">
                        <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">
                          About Us
                        </h6>
                        <h1 className="section-title">Who Are We ?</h1>
                        <p>{about_us[0]?.content_1}</p>
                        <p>{about_us[0]?.content_2}</p>
                      </div>
                      <div className="btn-wrapper animated">
                        <Link
                          to="Contact-Us"
                          className="theme-btn-1 btn btn-effect-1"
                        >
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                // Render a loading state, placeholder, or handle the absence of data in another way
                <p>Loading...</p>
              )}
            </div>
          </div>
        </div>
        {/* ABOUT US AREA END */}
      </div>
    </div>
  );
}

export default AboutUs;
