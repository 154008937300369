import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function IndividualGallery() {

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use smooth scrolling for a smoother animation (optional)
      });
    };

    // Call the scrollToTop function when the component mounts
    scrollToTop();
  }, []);


  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const galleryId = queryParams.get("gallery_id");


  let [gallery, setgallery] = useState([]);
  useEffect(() => {
    getgallery();
    // eslint-disable-next-line
  }, []);

  const getgallery = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/product_gallery/${galleryId}`)
        .then(function (response) {
          setgallery(response.data);
        });
        const script = document.createElement("script");
        script.src = "assets/js/main.js";
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
  
        // Clean up the script when the component unmounts
        return () => {
          document.head.removeChild(script);
        };
    } catch (err) {
      console.error(err);
    }
  };

  let gallery_html_data = gallery.map((gallery, key) => (
    <div
      key={key}
      className="ltn__gallery-item filter_category_3 col-md-3 col-sm-6 col-12"
    >
      <div className="ltn__gallery-item-inner">
        <div className="ltn__gallery-item-img">
          <a
            href={`.${gallery?.image_path.substring(16, 1000) || ""}`}
            data-rel="lightcase:myCollection"
          >
            <Image
              src={`.${gallery?.image_path.substring(16, 1000) || ""}`}
              alt={gallery.product_name}
            />
            <span className="ltn__gallery-action-icon">
              <i className="fas fa-search" />
            </span>
          </a>
        </div>
        <div className="ltn__gallery-item-info">
          <h4>
            <a href="portfolio-details.html">{gallery.product_name}</a>
          </h4>
        </div>
      </div>
    </div>
  ));


  return (
    <div>
      <div>
        <div
          className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "
          data-bs-bg="assets/img/bg/14.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner">
                  <h1 className="page-title">Our Gallery</h1>
                  <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <Link to="Home">
                          <span className="ltn__secondary-color">
                            <i className="fas fa-home" />
                          </span>{" "}
                          Home
                        </Link>
                      </li>
                      <li>Gallery</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__gallery-area mb-120">
          <div className="container">
            <div className="ltn__gallery-active row ltn__gallery-style-1">
              <div className="ltn__gallery-sizer col-1" />
              {gallery_html_data}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndividualGallery;
