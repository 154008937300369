import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";

function Contact() {
  useEffect(() => {
    // Load external JavaScript scripts here
    const script1 = document.createElement("script");
    script1.src = "assets/js/main.js";
    script1.async = true;
    document.body.appendChild(script1);
    return () => {
      document.body.removeChild(script1);
    };
  }, []);

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Use smooth scrolling for a smoother animation (optional)
      });
    };

    // Call the scrollToTop function when the component mounts
    scrollToTop();
  }, []);

  const [inputs, setInputs] = useState([]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios
        .post(`${process.env.REACT_APP_API_BASE}/enquiry`, inputs)
        .then(function (response) {
          window.alert("Thanks! We will Contact You Soon");
          window.location.reload();
        });
    } catch (err) {
      console.error(err);
    }
  };

  const [products, setproducts] = useState([]);

  useEffect(() => {
    getproducts();
  }, []);

  const getproducts = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_BASE}/products/`)
        .then(function (response) {
          setproducts(response.data);
        });
    } catch (err) {
      console.error(err);
    }
  };

  /*   let products_html_data = products.map((product, key) => (
    <option key={key} value={`${product.product_name}`}> {product.product_name} </option>
  )); */

  // Check if products array has values
  if (products.length === 0) {
    // You can return a loading indicator or any other content
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        <div className="ltn__utilize-overlay" />
        <div
          className="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image "
          data-bs-bg="img/bg/14.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner">
                  <h1 className="page-title">Contact Us</h1>
                  <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <a href="Home">
                          <span className="ltn__secondary-color">
                            <i className="fas fa-home" />
                          </span>{" "}
                          Home
                        </a>
                      </li>
                      <li>Contact</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__contact-address-area mb-90">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src="assets/img/icons/10.png" alt="Icon" />
                  </div>
                  <h3>Email Address</h3>
                  <p>enquiry@bagrecha.in</p>
                  <br></br>
                  <br></br>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src="assets/img/icons/11.png" alt="Icon" />
                  </div>
                  <h3>Phone Number</h3>
                  <p>
                    +91 95140 10000
                    <br />
                    +91 93448 33682
                  </p>
                  <br></br>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src="assets/img/icons/12.png" alt="Icon" />
                  </div>
                  <h3>Registered Office</h3>
                  <p>
                    850/5 , Mettupalayam Road , <br></br>3rd Floor ,Opp.Flower
                    Market Bus Stop , Coimbatore-641002 , TamilNadu , India{" "}
                  </p>
                </div>
              </div>
              <h4>Our Branches</h4>
              <div className="col-lg-3">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src="assets/img/icons/snega_glass.png" alt="Icon" />
                  </div>
                  <h3>SNEHA GLASSES PVT. LTD.,</h3>
                  <p>11/386, Chemmanamged, Near Govt, Hr. Sec. School, Kanjikode, Kerala - 673021.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src="assets/img/icons/visaka_glass.png" alt="Icon" />
                  </div>
                  <h3>SRI VISAKHA GLASS HOUSE</h3>
                  <p>S.No, 50/2, Warehouse-A2, Plot-12, IDA Block A, Mindi,Visakhapatnam
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src="assets/img/icons/milan.png" alt="Icon" />
                  </div>
                  <h3>MILAN GLASS HOUSE,</h3>
                  <p>Next to Ganpati Mandir, Chouk, Maharashtra - 410206
                  </p>
                </div>
              </div>
              <h4>Factory</h4>
              <div className="col-lg-3">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src="assets/img/icons/real_glass.png" alt="Icon" />
                  </div>
                  <h3>REAL GLASS</h3>
                  <p>Plot No. A-B/2 S.No. 32/2, Sipcot, Thervoykandigal Main Road,
                  </p>
                </div>
              </div>
              <h4>Ware House</h4>
              <div className="col-lg-4">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                  <div className="ltn__contact-address-icon">
                    <img src="assets/img/icons/12.png" alt="Icon" />
                  </div>
                  <h3>BAGRECHA ENTERPRISES LIMITED</h3>
                  <p>Plot No. A-B/2 S.No. 32/2, Sipcot, Thervoykandigal Main Road,
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__contact-message-area mb-120 mb--100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__form-box contact-form-box box-shadow white-bg">
                  <h4 className="title-2">Get A Quote</h4>
                  <form id="contact-form" method="post">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-item input-item-name ltn__custom-icon">
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter your name"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-item input-item-email ltn__custom-icon">
                          <input
                            type="email"
                            name="email"
                            placeholder="Enter email address"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/*                             <div className="input-item">
                              <select className="nice-select" name="product_name" onChange={handleChange}>
                                <option  value="" disabled selected>Select Product Name</option>
                                {products_html_data}
                              </select>
                            </div> */}
                      </div>
                      <div className="col-md-6=12">
                        <div className="input-item input-item-phone ltn__custom-icon">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Enter phone number"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="input-item input-item-textarea ltn__custom-icon">
                      <textarea
                        name="message"
                        placeholder="Enter message"
                        defaultValue={""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="btn-wrapper mt-0">
                      <button
                        className="btn theme-btn-1 btn-effect-1 text-uppercase"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                    <p className="form-messege mb-0 mt-20" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="google-map mb-120">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15665.505236267509!2d76.949964!3d11.010367000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8591cfffffffd%3A0xd2810b3a24f0d74a!2sBagrecha%20Enterprises%20Private%20Limited!5e0!3m2!1sen!2sin!4v1699973723375!5m2!1sen!2sin"
            width="100%"
            height="100%"
            frameBorder={0}
            allowFullScreen
            aria-hidden="false"
            tabIndex={0}
          />
        </div>
      </div>
    </div>
  );
}

export default Contact;
