import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-2 plr--5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      <img src="assets/img/logo-2.png" alt="Logo" />
                    </div>
                  </div>
                  <p>
                    Bagrecha Enterprises Limited [BEL] was founded in 1998. It
                    is a Public Limited Company with the<br></br> sole objective
                    of developing Glass Trade in Pan India
                  </p>
                </div>
              </div>
              <div className="col-xl-5 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-about-widget">
                  <h4 className="footer-title">Contact Us</h4>
                  <div className="footer-address">
                    <ul>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-placeholder" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            850/5 , Mettupalayam Road , 3rd Floor ,Opp.Flower
                            Market Bus Stop , Coimbatore-641002 , TamilNadu ,
                            India
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-call" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="tel:+919514010000">+(91) 95140 10000</a>
                          </p>
                        </div>
                        <div className="footer-address-icon">
                          <i className="icon-call" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="tel:+919344833682">+(91) 93448 33682</a>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-mail" />
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="mailto:enquiry@bagrecha.in">
                              enquiry@bagrecha.in
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Quick Links</h4>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <Link to="Home">Home</Link>
                      </li>
                      <li>
                        <Link to="About-Us">About Us</Link>
                      </li>
                      <li>
                        <Link to="Products">Products</Link>
                      </li>
                      <li>
                        <Link to="Gallery">Gallery</Link>
                      </li>
                      <li>
                        <Link to="Contact-Us">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ltn__copyright-area ltn__copyright-2 section-bg-7  plr--5">
          <div className="container-fluid ltn__border-top-2">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="ltn__copyright-design clearfix">
                  <p>
                    Developed By @{" "}
                    <a href="https://dailydaysolutions.com">
                      Daily Day Solutions{" "}
                    </a>{" "}
                    <span className="current-year" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
